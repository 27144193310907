@font-face {
	font-weight: 300;
	font-style: normal;
	font-family: 'Lab-grotesque';
	font-display: optional;
	src: url('../css/fonts/LabGrotesque-Light.eot');
	src: url('../css/fonts/LabGrotesque-Light.eot?#iefix') format('embedded-opentype'), 
			url('../css/fonts/LabGrotesque-Light.woff2') format('woff2'), 
			url('../css/fonts/LabGrotesque-Light.woff') format('woff'), 
			url('../css/fonts/LabGrotesque-Light.ttf') format('truetype');
}

@font-face {
	font-weight: 300;
	font-style: italic;
	font-family: 'Lab-grotesque';
	font-display: optional;
	src: url('../css/fonts/LabGrotesque-LightItalic.eot');
	src: url('../css/fonts/LabGrotesque-LightItalic.eot?#iefix') format('embedded-opentype'), 
			url('../css/fonts/LabGrotesque-LightItalic.woff2') format('woff2'), 
			url('../css/fonts/LabGrotesque-LightItalic.woff') format('woff'), 
			url('../css/fonts/LabGrotesque-LightItalic.ttf') format('truetype');
}

@font-face {
	font-weight: 400;
	font-style: normal;
	font-family: 'Lab-grotesque';
	font-display: optional;
	src: url('../css/fonts/LabGrotesque-Regular.eot');
	src: url('../css/fonts/LabGrotesque-Regular.eot?#iefix') format('embedded-opentype'), 
			url('../css/fonts/LabGrotesque-Regular.woff2') format('woff2'), 
			url('../css/fonts/LabGrotesque-Regular.woff') format('woff'), 
			url('../css/fonts/LabGrotesque-Regular.ttf') format('truetype');
}

@font-face {
	font-weight: 400;
	font-style: italic;
	font-family: 'Lab-grotesque';
	font-display: optional;
	src: url('../css/fonts/LabGrotesque-Italic.eot');
	src: url('../css/fonts/LabGrotesque-Italic.eot?#iefix') format('embedded-opentype'), 
			url('../css/fonts/LabGrotesque-Italic.woff2') format('woff2'), 
			url('../css/fonts/LabGrotesque-Italic.woff') format('woff'), 
			url('../css/fonts/LabGrotesque-Italic.ttf') format('truetype');
}

@font-face {
	font-weight: 500;
	font-style: normal;
	font-family: 'Lab-grotesque';
	font-display: optional;
	src: url('../css/fonts/LabGrotesque-Medium.eot');
	src: url('../css/fonts/LabGrotesque-Medium.eot?#iefix') format('embedded-opentype'), 
			url('../css/fonts/LabGrotesque-Medium.woff2') format('woff2'), 
			url('../css/fonts/LabGrotesque-Medium.woff') format('woff'), 
			url('../css/fonts/LabGrotesque-Medium.ttf') format('truetype');
}

@font-face {
	font-weight: 500;
	font-style: italic;
	font-family: 'Lab-grotesque';
	font-display: optional;
	src: url('../css/fonts/LabGrotesque-MediumItalic.eot');
	src: url('../css/fonts/LabGrotesque-MediumItalic.eot?#iefix') format('embedded-opentype'), 
			url('../css/fonts/LabGrotesque-MediumItalic.woff2') format('woff2'), 
			url('../css/fonts/LabGrotesque-MediumItalic.woff') format('woff'), 
			url('../css/fonts/LabGrotesque-MediumItalic.ttf') format('truetype');
}

@font-face {
	font-weight: 700;
	font-style: normal;
	font-family: 'Lab-grotesque';
	font-display: optional;
	src: url('../css/fonts/LabGrotesque-Bold.eot');
	src: url('../css/fonts/LabGrotesque-Bold.eot?#iefix') format('embedded-opentype'), 
			url('../css/fonts/LabGrotesque-Bold.woff2') format('woff2'), 
			url('../css/fonts/LabGrotesque-Bold.woff') format('woff'), 
			url('../css/fonts/LabGrotesque-Bold.ttf') format('truetype');
}

@font-face {
	font-weight: 700;
	font-style: italic;
	font-family: 'Lab-grotesque';
	font-display: optional;
	src: url('../css/fonts/LabGrotesque-BoldItalic.eot');
	src: url('../css/fonts/LabGrotesque-BoldItalic.eot?#iefix') format('embedded-opentype'), 
			url('../css/fonts/LabGrotesque-BoldItalic.woff2') format('woff2'), 
			url('../css/fonts/LabGrotesque-BoldItalic.woff') format('woff'), 
			url('../css/fonts/LabGrotesque-BoldItalic.ttf') format('truetype');
}

@font-face {
	font-weight: 900;
	font-style: normal;
	font-family: 'Lab-grotesque';
	font-display: optional;
	src: url('../css/fonts/LabGrotesque-Black.eot');
	src: url('../css/fonts/LabGrotesque-Black.eot?#iefix') format('embedded-opentype'), 
			url('../css/fonts/LabGrotesque-Black.woff2') format('woff2'), 
			url('../css/fonts/LabGrotesque-Black.woff') format('woff'), 
			url('../css/fonts/LabGrotesque-Black.ttf') format('truetype');
}

@font-face {
	font-weight: 900;
	font-style: italic;
	font-family: 'Lab-grotesque';
	font-display: optional;
	src: url('../css/fonts/LabGrotesque-Black.eot');
	src: url('../css/fonts/LabGrotesque-Black.eot?#iefix') format('embedded-opentype'), 
			url('../css/fonts/LabGrotesque-Black.woff2') format('woff2'), 
			url('../css/fonts/LabGrotesque-Black.woff') format('woff'), 
			url('../css/fonts/LabGrotesque-Black.ttf') format('truetype');
}

@font-face {
	font-weight: bold;
	font-style: normal;
	font-family: 'Freight';
	font-display: optional;
	src: url('../css/fonts/Freight-Bold.eot');
	src: url('../css/fonts/Freight-Bold.eot?#iefix') format('embedded-opentype'), 
			url('../css/fonts/Freight-Bold.woff2') format('woff2'), 
			url('../css/fonts/Freight-Bold.woff') format('woff'), 
			url('../css/fonts/Freight-Bold.ttf') format('truetype');
}

@font-face {
	font-weight: normal;
	font-style: italic;
	font-family: 'Freight';
	font-display: optional;
	src: url('../css/fonts/Freight-Italic.eot');
	src: url('../css/fonts/Freight-Italic.eot?#iefix') format('embedded-opentype'), 
			url('../css/fonts/Freight-Italic.woff2') format('woff2'), 
			url('../css/fonts/Freight-Italic.woff') format('woff'), 
			url('../css/fonts/Freight-Italic.ttf') format('truetype');
}

@font-face {
	font-weight: 400;
	font-style: italic;
	font-family: 'Open Sans';
	font-display: optional;
	src: url('../css/fonts/OpenSans-italic.eot');
	src: url('../css/fonts/OpenSans-italic.eot?#iefix') format('embedded-opentype'), 
			url('../css/fonts/OpenSans-italic.woff2') format('woff2'), 
			url('../css/fonts/OpenSans-italic.woff') format('woff'), 
			url('../css/fonts/OpenSans-italic.ttf') format('truetype');
}
